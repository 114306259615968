.filterWrapper {
  margin-bottom: 20px;
  .form-group {
    margin-right: 20px;
  }
}

.table-row-container {
  th {
    display: flex;
    align-items: center;
  }
}
