body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.filterWrapper {
  margin-bottom: 20px; }
  .filterWrapper .form-group {
    margin-right: 20px; }

.table-row-container th {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }

.sticky-buttons {
  margin-top: 20px; }
  .sticky-buttons button {
    margin-right: 15px; }

img {
  max-width: 200px;
  margin: 10px; }

html,
.body {
  height: calc(100vh - 60px); }

.body {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #fff; }

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto; }

.form-signin .checkbox {
  font-weight: 400; }

.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px; }

.form-signin .form-control:focus {
  z-index: 2; }

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.news-detail-page img {
  width: 100%; }

.container button {
  float: right;
  display: block;
  margin-bottom: 20px; }

.container .page-item.active .page-link {
  z-index: 0; }

h2 {
  text-align: center; }

.create-form {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  text-align: center; }
  .create-form .input-container {
    margin: 20px 0; }
    .create-form .input-container label {
      display: block; }
    .create-form .input-container input,
    .create-form .input-container textarea {
      display: block;
      min-width: 300px; }

.modal-close {
  position: absolute;
  top: 5px;
  right: 5px; }

.navbar-container ul {
  display: -webkit-flex;
  display: flex; }

html {
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: scroll; }
  html * {
    font-family: "Microsoft Yahei", sans-serif; }

.page {
  margin-top: 20px; }

.table .table-row-container:hover {
  cursor: pointer; }

.table .table-row-container td {
  min-width: 120px;
  font-size: 12px;
  padding-bottom: 0; }

.table .table-row-container .bottom-gutter {
  padding-top: 0.4rem; }

.table .table-row-container th {
  font-size: 14px;
  max-width: 600px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-align-items: center;
          align-items: center; }
  .table .table-row-container th i {
    color: orange;
    margin-right: 10px; }
  .table .table-row-container th img {
    height: 20px;
    margin-right: 10px;
    vertical-align: -5px; }

.pagination {
  margin: 50px 0; }
  .pagination:hover {
    cursor: pointer; }
  .pagination .page-item .page-link {
    color: #000; }
  .pagination .page-item.active .page-link {
    background-color: #333;
    color: #fff; }

