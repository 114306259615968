.container {
  button {
    float: right;
    display: block;
    margin-bottom: 20px;
  }

  .page-item.active .page-link {
    z-index: 0;
  }
}

h2 {
  text-align: center;
}

.create-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .input-container {
    margin: 20px 0;
    label {
      display: block;
    }
    input,
    textarea {
      display: block;
      min-width: 300px;
    }
  }
}

.modal-close {
  position: absolute;
  top: 5px;
  right: 5px;
}
