.sticky-buttons {
  margin-top: 20px;
  button {
    margin-right: 15px;
  }
}

img {
  max-width: 200px;
  margin: 10px;
}
