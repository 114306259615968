html {
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: scroll;
  * {
    font-family: "Microsoft Yahei", sans-serif;
  }
}
.page {
  margin-top: 20px;
}

.table {
  .table-row-container {
    &:hover {
      cursor: pointer;
    }
    td {
      min-width: 120px;
      font-size: 12px;
      padding-bottom: 0;
    }

    .bottom-gutter {
      padding-top: 0.4rem;
    }

    th {
      font-size: 14px;
      max-width: 600px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      align-items: center;
      i {
        color: orange;
        margin-right: 10px;
      }
      img {
        height: 20px;
        margin-right: 10px;
        vertical-align: -5px;
      }
    }
  }
}

.pagination {
  margin: 50px 0;
  &:hover {
    cursor: pointer;
  }

  .page-item {
    .page-link {
      color: #000;
    }
    &.active {
      .page-link {
        background-color: #333;
        color: #fff;
      }
    }
  }
}
